import useAuthStore from '~/store/auth';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = useNuxtApp();
  if (to.query?.verify) {
    return nuxtApp.runWithContext(() =>
      navigateTo(
        {
          path: '/',
          query: {
            verify: to.query.verify,
          },
        },
        {
          redirectCode: 301,
        }
      )
    );
  } else if (to.query?.verifyandsetpassword) {
    return nuxtApp.runWithContext(() =>
      navigateTo(
        {
          path: '/',
          query: {
            verifyandsetpassword: to.query.verifyandsetpassword,
          },
        },
        {
          redirectCode: 301,
        }
      )
    );
  } else if (to.query?.resetpassword) {
    return nuxtApp.runWithContext(() =>
      navigateTo(
        {
          path: '/',
          query: {
            resetpassword: to.query.resetpassword,
          },
        },
        {
          redirectCode: 301,
        }
      )
    );
  }

  const authStore = useAuthStore();

  if (!authStore.token) {
    authStore.setShowLoginModal(true);
    if (from.path !== '/account') {
      return false;
    } else {
      return nuxtApp.runWithContext(() =>
        navigateTo(
          {
            path: '/',
            query: {
              login: 'true',
            },
          },
          {
            redirectCode: 301,
          }
        )
      );
    }
  }
});
